export const Blue = {
  900: "#174ea6",
  800: "#185abc",
  700: "#1967d2",
  600: "#1a73e8",
  500: "#4285f4",
  100: "#d2e3fc",
  50: "#e8f0fe"
};

export const Red = {
  900: "#a50e0e",
  800: "#b31412",
  700: "#c5221f",
  600: "#d93025",
  500: "#ea4335",
  100: "#fad2cf",
  50: "#fce8e6"
};

export const Yellow = {
  900: "#e37400",
  800: "#ea8600",
  700: "#f29900",
  600: "#f9ab00",
  500: "#fbbc04",
  100: "#feefc3",
  50: "#fef7e0"
};

export const Green = {
  900: "#0d652d",
  800: "#137333",
  700: "#188038",
  600: "#1e8e3e",
  500: "#34a853",
  100: "#ceead6",
  50: "#e6f4ea"
};

export const Grey = {
  900: "#202124",
  800: "#3c4043",
  700: "#5f6368",
  600: "#80868b",
  500: "#9aa0a6",
  100: "#f1f3f4",
  50: "#f8f9fa"
};
