import React, { useRef, useLayoutEffect, useState } from "react";
import Color from "color";
import { Label } from "./Label";

interface Props {
  label: string;
  value: number;
  ratio: number;
  width: string;
  fillColor: string;
  showValue: boolean;
  visualQuestion: boolean;
}
export const Bar: React.FC<Props> = ({
  label,
  value,
  ratio,
  width,
  fillColor,
  visualQuestion,
  showValue: optShowValue
}) => {
  const barRef = useRef({} as HTMLInputElement);
  const valueRef = useRef({} as HTMLInputElement);
  const [showValue, setShowValue] = useState(true);
  useLayoutEffect(() => {
    const barReact = barRef.current.getBoundingClientRect();
    const valueRect = valueRef.current.getBoundingClientRect();
    setShowValue(barReact.width > valueRect.width);
  }, [value, optShowValue]);
  const figureRef = useRef({} as HTMLInputElement);
  const [figureWidth, setFigureWidth] = useState(0);
  const labelRef = useRef({} as HTMLInputElement);
  useLayoutEffect(() => {
    if (visualQuestion) {
      const figureRect = figureRef.current.getBoundingClientRect();
      setFigureWidth(figureRect.height);
    } else {
      labelRef.current.style.display = "flex";
    }
  }, [label, visualQuestion]);
  return (
    <React.Fragment key={label}>
      {visualQuestion ? (
        <div className="label variant-figure">
          <div
            className="figure"
            ref={figureRef}
            style={{
              backgroundImage: `url(https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_STORAGE_BUCKET_ID}/images/${label})`,
              width: figureWidth
            }}
          />
        </div>
      ) : (
        <div className="label" ref={labelRef}>
          <Label>{label}</Label>
        </div>
      )}
      <div className="value">
        <div
          ref={barRef}
          className="bar"
          style={{
            width: width,
            backgroundColor: fillColor
          }}
        >
          <span className="number" ref={valueRef}>
            {optShowValue && showValue && value}
          </span>
        </div>
        <span
          className="ratio"
          style={{
            color: fillColor,
            filter: `drop-shadow(2px 2px 1px ${Color(fillColor).lighten(0.8)})`
          }}
        >
          {value}
        </span>
      </div>
    </React.Fragment>
  );
};
