import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useLayoutEffect
} from "react";

interface Props {
  children: string;
}
export const Label: React.FC<Props> = ({ children }) => {
  const wrapperRef = useRef({} as HTMLDivElement);
  const [fontSize, setFontSize] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const getAndSetClientWidth = () => {
    const dom = wrapperRef.current;
    if (dom) {
      setClientWidth(dom.clientWidth - 6);
    }
  }
  const handleWindowSizeChange = useCallback(() => {
    getAndSetClientWidth();
  }, []);
  useEffect(() => {
    getAndSetClientWidth();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);
  useLayoutEffect(() => {
    const dom = wrapperRef.current;
    if (dom) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (context) {
        const domStyle = window.getComputedStyle(dom, null);
        context.font = `24px ${domStyle.getPropertyValue("font-family")}`;
        const metrics = context.measureText(children);
        const fitFontSizeRatio = clientWidth / metrics.width;
        let perFontSize = Math.ceil(24 * fitFontSizeRatio);
        if (perFontSize < 30) {
          perFontSize = 30;
        }
        if (perFontSize > 120) {
          perFontSize = 120;
        }
        setFontSize(perFontSize);
      }
    }
  }, [children, clientWidth]);
  return (
    <div className="autolabel" ref={wrapperRef} style={{ fontSize: fontSize }}>
      {children}
    </div>
  );
};
