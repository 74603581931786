import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { createConsumer, CableMessage } from "@rails/actioncable";
import { Chart, Datum } from "./Chart";
import { Answer } from "./Answer";
import "./App.css";
const se1 = require("./audios/answer_show.mp3");
const se2 = require("./audios/answer_open.mp3");

interface QuestionResultDatum {
  choices: Array<string>;
  results: Array<number>;
  results_rate: Array<number>;
  visual_question: boolean;
  quiz: boolean;
  correctChoices: Array<boolean>;
}

interface Prop {
  token: string;
  questionId: string;
  apiHost: string;
  conferenceId: string;
  actionCable: string;
}

const blinkWait = 5000;
const blinkDuration = 2000;

const App: React.FC<Prop> = ({
  token,
  questionId,
  apiHost,
  conferenceId,
  actionCable
}) => {
  const [data, setData] = useState<Array<Datum>>([]);
  const [visualQuestion, setVisualQuestion] = useState(false);
  const [quiz, setQuiz] = useState(false);
  const [correctChoices, setCorrectChoices] = useState<Array<boolean>>([]);
  const [showValue, setShowValue] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get<QuestionResultDatum>(
        `${apiHost}/api/questions/${questionId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      const result_data: QuestionResultDatum = result.data;
      const tmpData = result_data.choices.map((choice, index) => ({
        label: choice,
        value: result_data.results[index],
        ratio: result_data.results_rate[index]
      }));
      setData(tmpData);
      setVisualQuestion(result_data.visual_question);
      setQuiz(result_data.quiz);
      setCorrectChoices(result_data.correctChoices);
      const timeoutId = setTimeout(() => fetchData(), 5000);
      return () => clearTimeout(timeoutId);
    };
    fetchData();
  }, [token, questionId, apiHost]);

  useEffect(() => {
    const cable = createConsumer(actionCable);
    cable.subscriptions.create(
      {
        channel: "ProjectorChannel",
        conference_id: conferenceId
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: (message: CableMessage) => {
          if (message.action === "change_chart_value_visibility") {
            setShowValue(JSON.parse(message.visibility));
          }
        }
      }
    );
  }, [actionCable, conferenceId]);
  const [showAnswerMark, setShowAnswerMark] = useState(false);
  const [animAnswerMark, setAnimAnswerMark] = useState(false);
  useEffect(() => {
    // se1Ref.current.play();
    setTimeout(() => {
      setShowAnswerMark(true);
      setAnimAnswerMark(true);
      // se2Ref.current.play();
      setTimeout(() => {
        setAnimAnswerMark(false);
      }, blinkDuration);
    }, blinkWait);
  }, []);
  const se1Ref = useRef({} as HTMLAudioElement);
  const se2Ref = useRef({} as HTMLAudioElement);
  return (
    <div className="App">
      <Chart
        data={data}
        showValue={showValue}
        visualQuestion={visualQuestion}
      />
      {quiz && showAnswerMark && (
        <Answer answers={correctChoices} tiktok={animAnswerMark} />
      )}
      <audio src={se1} ref={se1Ref} />
      <audio src={se2} ref={se2Ref} />
    </div>
  );
};

export default App;
