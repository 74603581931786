import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');
const questionId = urlParams.get('questionId');
const conferenceId = urlParams.get('conferenceId');

token && questionId && ReactDOM.render(
  <App
    token={token}
    questionId={questionId}
    conferenceId={conferenceId || ''}
    apiHost={process.env.REACT_APP_API_HOST || ''}
    actionCable={process.env.REACT_APP_ACTION_CABLE_URL || ''}
  />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
