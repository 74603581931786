import React from "react";

interface Props {
  answers: boolean[];
  tiktok: boolean;
}
export const Answer: React.FC<Props> = ({ answers, tiktok }) => {
  return (
    <div className="answer-chart">
      {answers.map((answer, index) => <div key={`answer-mark-${index}`} className={`answer is-${answer} is-anim-${tiktok}`} />)}
    </div>
  );
};
