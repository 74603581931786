import React, { useState, useEffect } from "react";
import { Bar } from "./Bar";
import { Red, Blue, Green, Yellow } from "./Colors";

const defaultColors = [
  Blue[500],
  Red[500],
  Yellow[500],
  Green[500],
  "#8f41b7",
  "#FFA500",
  "#12d7e2",
  "#ebeb0a",
  "#eba80a",
  "#e2b5b5",
  "#f7b6f1",
  "#d2d2d2",
  "#7fffd4",
  "#deb887"
];

export interface Datum {
  label: string;
  value: number;
  ratio: number;
}
interface Props {
  data: Array<Datum>;
  visualQuestion: boolean;
  showValue: boolean;
}
export const Chart: React.FC<Props> = ({ data, showValue, visualQuestion }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (data.length < 1) {
    return <React.Fragment />;
  }
  const maxValue = data.reduce((a, b) => (a.value > b.value ? a : b)).value;
  let datumSize = dimensions.height / data.length;
  if (datumSize < 50) {
    datumSize = dimensions.height / Math.ceil(data.length / 2);
  }
  if (datumSize > 150) {
    datumSize = 150;
  }
  return (
    <div
      className="chart"
      style={{
        fontSize: '10rem'
      }}
    >
      {data.map((datum, index) => (
        <Bar
          key={`chart-bar-${index}`}
          label={datum.label}
          value={datum.value}
          ratio={datum.ratio}
          width={`calc(${(datum.value / maxValue) * 100}% - 100px)`}
          fillColor={defaultColors[index]}
          showValue={false}
          visualQuestion={visualQuestion}
        />
      ))}
    </div>
  );
};
